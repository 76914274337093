.cont_slide {
  width: 100%;
  margin-top: 2.5vh;
  box-shadow: 0px 3px 5px #1d1d1d;
}
@media (min-width: 1280px) {
  .cont_slide {
    margin-top: 0;
  }
}
.cont_slide_slide {
  height: 45vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0 15px 20px;
}
@media (orientation: landscape) {
  .cont_slide_slide {
    height: 100vh;
  }
}
@media (min-width: 1280px) {
  .cont_slide_slide {
    height: 75vh;
    background-position: center;
    padding: 50px 35px;
    justify-content: flex-start;
  }
}
.cont_slide_slide > span {
  color: #fff;
  text-align: left;
  font-size: 31px;
  line-height: 29px;
  text-shadow: 0px 3px 4px #1d1d1d;
}
@media (min-width: 1280px) {
  .cont_slide_slide > span {
    font-size: 80px;
    line-height: 85px;
  }
}

