.cont__contact {
  width: 100%;
  height: fit-content;
}
.cont__contact > h2 {
  color: #FF0000;
  text-transform: uppercase;
}
.cont__contact a {
  color: #FF0000;
  text-decoration: none;
  border-bottom: 2px solid #020E28;
}
.cont__contact_form {
  width: 80%;
  height: fit-content;
  margin: 2.5vh auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1280px) {
  .cont__contact_form {
    width: 50%;
  }
}
.cont__contact_form > input, .cont__contact_form textarea, .cont__contact_form button {
  width: 100%;
  padding: 10px;
  background-color: #020E28;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  border: none;
  margin: 1.5vh 0;
  box-shadow: 0px 2px 2px #1d1d1d;
  transition: transform 0.7s, box-shadow 0.6s;
}
@media (min-width: 1280px) {
  .cont__contact_form > input, .cont__contact_form textarea, .cont__contact_form button {
    margin: 2.5vh 0;
  }
}
.cont__contact_form > input[id=submit], .cont__contact_form textarea[id=submit], .cont__contact_form button[id=submit] {
  background-color: #FF0000;
}
.cont__contact_form > input:focus, .cont__contact_form textarea:focus, .cont__contact_form button:focus {
  transform: translateY(-5px);
  box-shadow: 0px 1px 1px #1d1d1d;
  outline: none;
}

::placeholder {
  color: #fff;
}

