.cont__footer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1d1d1d;
  padding: 15px 0 0;
}
.cont__footer_inside {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1280px) {
  .cont__footer_inside {
    width: 75%;
    flex-direction: row;
    justify-content: space-between;
  }
}
.cont__footer_inside > ul {
  width: 80%;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-inline-start: 0;
  align-items: flex-start;
}
@media (min-width: 1280px) {
  .cont__footer_inside > ul {
    width: unset;
  }
}
.cont__footer_inside > ul > li {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 1.5vh auto;
}
.cont__footer_inside > ul > li > a {
  margin-left: 2.5vw;
  font-size: 12px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  text-align: left;
}
.cont__footer_inside > iframe {
  margin: 2.5vh 0;
}
.cont__footer_under {
  width: 100%;
  background-color: #000;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}
.cont__footer_under > p {
  color: #fff;
  font-weight: bold;
  margin: 0;
  font-size: 12px;
}
.cont__footer_under > img {
  width: 135px;
  object-fit: contain;
}

