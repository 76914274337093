$blu_color: #020E28;
$red_color: #FF0000;

.cont__home {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > p {
        text-align: left;
        width: 80%;

        @media(min-width: 1280px) {
            font-size: 21px;
        }
    }

    &_info {
        width: 100%;
        margin: 5vh 0;
        display: flex;
        flex-direction: column;
        list-style: none;
        align-items: center;
        justify-content: space-around;
        padding-inline-start: 0;

        @media(min-width: 1280px) {
            flex-direction: row;
        }

        & > li {
            margin: 5vh 0;

            @media(min-width: 1280px) {
                margin: 0;

                & > img {
                    height: 150px;
                    object-fit: contain;
                }
            }

            &:last-child {
                margin: 5vh 0 0;

                @media(min-width: 1280px) {
                    margin: 0;
                }
            }

            & > h2 {
                color: $red_color;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 18px;
            }
        }
    }

    &_descr {
        width: 100%;
        height: 35vh;
        background-image: url(../img/foto/4-grande.jpg);
        background-size: cover;
        background-repeat: none;
        background-position: center;
        padding: 0 25px;
        margin-top: 2.5vh;

        @media(orientation: landscape){
            height: 85vh;
            background-position: center;
        }

        @media(min-width: 1280px){
            height: 65vh;
            background-position-y: 100%;
            padding: 15px 35px;
        }

        & > h2 {
            color: #fff;
            text-align: left;
            font-weight: 500;

            @media(min-width: 1280px){
                font-size: 32px;
            }
        }
    }

    &_eco {
        width: 100%;
        height: fit-content;
        padding: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $blu_color;

        & > h2 {
            color: #fff;
            text-align: left;
            font-weight: bold;

            @media(min-width: 1280px){
                text-align: center;
            }
        } 

        @media(min-width: 1280px){
            & > img {
                width: 10%;
                object-fit: contain;
            }
        }
    }
}