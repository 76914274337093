$blu_color: #020E28;
$red_color: #FF0000;

.cont__contact {
    width: 100%;
    height: fit-content;

    & > h2 {
        color: $red_color;
        text-transform: uppercase;
    }

    & a {
        color: $red_color;
        text-decoration: none;
        border-bottom: 2px solid $blu_color;
    }

    &_form {
        width: 80%;
        height: fit-content;
        margin: 2.5vh auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media(min-width: 1280px){
            width: 50%;
        }

        & > input, textarea, button {
            width: 100%;
            padding: 10px;
            background-color: $blu_color;
            color: #fff;
            text-transform: uppercase;
            font-size: 16px;
            border: none;
            margin: 1.5vh 0;
            box-shadow: 0px 2px 2px #1d1d1d;
            transition: transform .7s, box-shadow .6s;

            @media(min-width: 1280px){
                margin: 2.5vh 0;
            }

            &[id="submit"] {
                background-color: $red_color;
            }

            &:focus {
                transform: translateY(-5px);
                box-shadow: 0px 1px 1px #1d1d1d;
                outline: none;
            }
        }
    }
}

::placeholder {
    color: #fff;
}