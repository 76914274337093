.cont__home {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cont__home > p {
  text-align: left;
  width: 80%;
}
@media (min-width: 1280px) {
  .cont__home > p {
    font-size: 21px;
  }
}
.cont__home_info {
  width: 100%;
  margin: 5vh 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: center;
  justify-content: space-around;
  padding-inline-start: 0;
}
@media (min-width: 1280px) {
  .cont__home_info {
    flex-direction: row;
  }
}
.cont__home_info > li {
  margin: 5vh 0;
}
@media (min-width: 1280px) {
  .cont__home_info > li {
    margin: 0;
  }
  .cont__home_info > li > img {
    height: 150px;
    object-fit: contain;
  }
}
.cont__home_info > li:last-child {
  margin: 5vh 0 0;
}
@media (min-width: 1280px) {
  .cont__home_info > li:last-child {
    margin: 0;
  }
}
.cont__home_info > li > h2 {
  color: #FF0000;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
}
.cont__home_descr {
  width: 100%;
  height: 35vh;
  background-image: url(../img/foto/4-grande.jpg);
  background-size: cover;
  background-repeat: none;
  background-position: center;
  padding: 0 25px;
  margin-top: 2.5vh;
}
@media (orientation: landscape) {
  .cont__home_descr {
    height: 85vh;
    background-position: center;
  }
}
@media (min-width: 1280px) {
  .cont__home_descr {
    height: 65vh;
    background-position-y: 100%;
    padding: 15px 35px;
  }
}
.cont__home_descr > h2 {
  color: #fff;
  text-align: left;
  font-weight: 500;
}
@media (min-width: 1280px) {
  .cont__home_descr > h2 {
    font-size: 32px;
  }
}
.cont__home_eco {
  width: 100%;
  height: fit-content;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #020E28;
}
.cont__home_eco > h2 {
  color: #fff;
  text-align: left;
  font-weight: bold;
}
@media (min-width: 1280px) {
  .cont__home_eco > h2 {
    text-align: center;
  }
}
@media (min-width: 1280px) {
  .cont__home_eco > img {
    width: 10%;
    object-fit: contain;
  }
}

