$blu_color: #020E28;
$red_color: #FF0000;

.cont__about {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

    &_descr {
        width: 100%;
        height: 35vh;
        background-image: url(../img/foto/4-grande.jpg);
        background-size: cover;
        background-repeat: none;
        background-position: center;
        padding: 2.5px 25px;

        @media(orientation: landscape){
            height: 85vh;
            background-position: center;
        }

        @media(min-width: 1280px){
            height: 65vh;
            background-position-y: 100%;
            margin: 7.5vh 0;
        }

        & > h2 {
            color: #fff;
            text-align: left;
            font-weight: 500;
            margin-top: 20px;

            @media(min-width: 1280px){
                font-size: 31px;
            }
        }
    }

    &_inside {
        margin: 5vh auto;

        & > h2 {
            color: $red_color;
        }

        & > p {
            width: 80%;
            font-size: 21px;
            margin: 2.5vh auto;
            text-align: left;
        }

        & > ul {
            width: 100%;
            margin: 5vh auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            list-style: none;
            padding-inline-start: 0;

            @media(min-width: 1280px){
                width: 90%;
                flex-direction: row;
                flex-wrap: wrap;
            }

            & > li {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 400px;
                padding: 10px 15px;
                margin: 2.5vh auto;
                background-size: cover;
                clip-path: circle();

                &[id="home"] {
                    background-position: center;
                    background-image: url('../img/foto/casa.png');
                }
        
                &[id="business"] {
                    background-position: center;
                    background-image: url('../img/foto/business.png');
                }
        
                &[id="flat"] {
                    background-position: center;
                    background-image: url('../img/foto/condomini.png');
                }

                & > p {
                    font-size: 10px !important;
                    color: #fff;
                    width: 80%;

                    @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
                        width: 25%;
                    }

                    @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
                        width: 50%;
                    }
                }

                @media(min-width: 1280px){
                    width: 45%;
                    height: 500px;

                    & > p {
                        width: 60%;
                    }
                }

                & > img {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }
            }
        }
    }
}