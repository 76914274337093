$blu_color: #020E28;
$red_color: #FF0000;

.cont__services {
    width: 75%;
    display: flex;
    flex-direction: column;
    padding-inline-start: 0;
    align-items: center;
    list-style: none;
    margin: 5vh auto;

    @media(min-width: 1280px){
        width: 85%;
        flex-direction: row;
        justify-content: space-between;
        margin: 1vh auto;
    }

    & > li {
        width: 100%;
        height: 40vh;
        clip-path: circle();
        padding: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-position: center;
        margin: 2.5vh 0;

        @media(orientation: landscape){
            height: 90vh;
        }

        @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
            height: 50vh;
        } 
        
        @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
            height: 65vh;
        } 

        @media(min-width: 1280px){
            width: 30%;
            height: 60vh;
            margin: 0 0;
        }

        & > p {
            color: #fff;
            font-weight: bold;
            margin-top: 10px;
            width: fit-content;

            @media(orientation: landscape){
                width: 65%;
            }
        }

        & > a  {
            color: #fff;
            text-decoration: none;
            font-weight: bold;
            font-size: 12px;
            transition: color .6s;
            background-color: $red_color;
            transition: color .6s, background-color .7s;
            border-radius: 25px;
            padding: 5px;
            text-transform: uppercase;
            line-height: 15px;
            width: 65%;
            margin-top: -10px;

            @media(orientation: landscape){
                width: 40%;
                margin-top: unset;
            }

            &:hover {
                background-color: $blu_color;
                color: $red_color;
            }
        }

        &[id="home"] {
            background-image: url('../img/foto/casa.png');
        }

        &[id="business"] {
            background-image: url('../img/foto/business.png');
        }

        &[id="flat"] {
            background-image: url('../img/foto/condomini.png');
        }
    }
}