.cont__footer {
    width: 100%;
    height: fit-content;
    // padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1d1d1d;
    padding: 15px 0 0;

    &_inside {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media(min-width: 1280px){
            width: 75%;
            flex-direction: row;
            justify-content: space-between;
        }

        & > ul {
            width: 80%;
            display: flex;
            flex-direction: column;
            list-style: none;
            padding-inline-start: 0;
            align-items: flex-start;

            @media(min-width: 1280px){
                width: unset;
            }

            & > li {
                display: flex;
                width: 100%;
                align-items: center;
                margin: 1.5vh auto;

                & > a {
                    margin-left: 2.5vw;
                    font-size: 12px;
                    color: #fff;
                    text-decoration: none;
                    font-weight: bold;
                    text-align: left;
                }
            }
        }
        
        & > iframe {
            margin: 2.5vh 0;
        }
    }


    &_under {
        width: 100%;
        background-color: #000;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;

        & > p {
            color: #fff;
            font-weight: bold;
            margin: 0;
            font-size: 12px;
        }

        & > img {
            width: 135px;
            object-fit: contain;
        }
    }
}