.cont__header {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1280px) {
  .cont__header_desktop {
    display: flex;
    align-items: center;
  }
}
.cont__header_cta {
  width: 100%;
  height: fit-content;
  padding: 5px 25px;
  background-color: #020E28;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
@media (min-width: 1280px) {
  .cont__header_cta {
    flex-direction: row;
    justify-content: flex-start;
    padding: 25px 0;
  }
}
.cont__header_cta > a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 13px;
  transition: color 0.6s;
  transition: transform 0.8s;
  display: flex;
  align-items: center;
  margin: 1.25vh 0;
  text-align: left;
}
@media (min-width: 1280px) {
  .cont__header_cta > a {
    margin: 0 2.5vw;
  }
  .cont__header_cta > a:first-of-type {
    margin: 0 0 0 2.5vw;
  }
}
.cont__header_cta > a img {
  margin-right: 15px;
  width: 25px;
  height: 25px;
  object-fit: contain;
}
.cont__header_cta > a:hover {
  color: #FF0000;
  transform: translateX(10px);
}
.cont__header_logo {
  padding: 35px;
}
@media (min-width: 1280px) {
  .cont__header_logo {
    height: 150px;
  }
  .cont__header_logo img {
    height: 100%;
    object-fit: contain;
  }
}
.cont__header_menuZone {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}
.cont__header_menuZone_desktop {
  display: none;
}
@media (min-width: 1280px) {
  .cont__header_menuZone_desktop {
    display: block;
  }
  .cont__header_menuZone_desktop > ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
  }
  .cont__header_menuZone_desktop > ul > li {
    margin: 0 1.5vw;
  }
  .cont__header_menuZone_desktop > ul > li > a {
    color: #020E28;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 2px solid #020E28;
    padding: 0 10px 10px;
    transition: color 0.6s, border-bottom 0.6s;
  }
  .cont__header_menuZone_desktop > ul > li > a:hover {
    color: #FF0000;
    border-bottom: 2px solid #FF0000;
  }
}
.cont__header_menuZone_inside {
  width: 100%;
  height: fit-content;
  padding: 45px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #020E28;
  z-index: 9999;
  transform: translateY(-115%);
  transition: transform 0.8s;
  box-shadow: 0px 2px 4px #020E28;
}
@media (orientation: landscape) {
  .cont__header_menuZone_inside {
    height: 85vh;
  }
}
@media (min-width: 1280px) {
  .cont__header_menuZone_inside {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .cont__header_menuZone_inside {
    height: 45vh;
  }
}
.cont__header_menuZone_inside > ul {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-inline-start: 0;
  list-style: none;
}
.cont__header_menuZone_inside > ul > li {
  margin: 2.5vh 0;
  transition: transform 0.7s, color 0.5s;
}
.cont__header_menuZone_inside > ul > li:hover {
  transform: translateX(10px);
}
.cont__header_menuZone_inside > ul > li:hover > a {
  color: #FF0000;
}
.cont__header_menuZone_inside > ul > li > a {
  text-decoration: none;
  color: #fff;
  font-size: 21px;
  font-weight: bold;
  text-transform: uppercase;
}
.cont__header_menuZone_cta {
  width: 125px;
  background-color: #FF0000;
  height: fit-content;
  border-radius: 25px;
  font-size: 12px;
  padding: 5px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  transition: transform 0.8s;
}
.cont__header_menuZone_cta:hover {
  transform: translateX(10px);
}
.cont__header_menuZone_menu {
  width: 35px;
  height: 35px;
  background-color: #FF0000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 5px;
  z-index: 99999;
}
@media (min-width: 1280px) {
  .cont__header_menuZone_menu {
    display: none;
  }
}
.cont__header_menuZone_menu:focus {
  outline: none;
}
.cont__header_menuZone_menu_line {
  width: 100%;
  height: 3px;
  background-color: #fff;
  margin: 2.5px 0;
}

.open {
  transform: translateY(0);
  position: fixed;
}

.no_bg {
  transition: all 0.6s;
  background-color: #020E28;
  position: fixed;
  right: 20px;
}

.changeColor {
  transition: all 0.7s;
  background-color: #FF0000;
}

