$blu_color: #020E28;
$red_color: #FF0000;

.cont__header {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

    @media(min-width: 1280px){
        &_desktop {
            display: flex;
            align-items: center;
        }
    }

    &_cta {
        width: 100%;
        height: fit-content;
        padding: 5px 25px;
        background-color: $blu_color;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @media(min-width: 1280px){
            flex-direction: row;
            justify-content: flex-start;
            padding: 25px 0;
        }

        & > a {
            color: #fff;
            text-decoration: none;
            font-weight: bold;
            font-size: 13px;
            transition: color .6s;
            transition: transform .8s;
            display: flex;
            align-items: center;
            margin: 1.25vh 0; 
            text-align: left;

            @media(min-width: 1280px){
                margin: 0 2.5vw;

                &:first-of-type {
                    margin: 0 0 0 2.5vw;
                }
            }
            
            & img {
                margin-right: 15px;
                width: 25px;
                height: 25px;
                object-fit: contain;
            }

            &:hover {
                color: $red_color;
                transform: translateX(10px);
            }
        }
    }

    &_logo {
        padding: 35px;

        @media(min-width: 1280px){
            height: 150px;

            & img {
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &_menuZone {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;

        &_desktop {
            display: none;

            @media(min-width: 1280px){
                display: block;

                & > ul {
                    display: flex;
                    flex-direction: row;
                    list-style: none;
                    padding-inline-start: 0;
                    margin: 0;

                    & > li {
                        margin: 0 1.5vw;

                        & > a {
                            color: $blu_color;
                            text-decoration: none;
                            font-size: 18px;
                            font-weight: bold;
                            text-transform: uppercase;
                            border-bottom: 2px solid $blu_color;
                            padding: 0 10px 10px;
                            transition: color .6s, border-bottom .6s;

                            &:hover {
                                color: $red_color;
                                border-bottom: 2px solid $red_color;
                            }
                        }
                    }
                }
            }
        }

        &_inside {
            width: 100%;
            height: fit-content;
            padding: 45px;
            position: absolute;
            top: 0;
            right: 0;
            background-color: $blu_color;
            z-index: 9999;
            transform: translateY(-115%);
            transition: transform .8s;
            box-shadow: 0px 2px 4px $blu_color;

            @media(orientation: landscape){
                height: 85vh;
            }

            @media(min-width: 1280px){
                display: none;
            }

            @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
                height: 45vh;
            } 

            & > ul {
                width: 80%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-inline-start: 0;
                list-style: none;

                & > li {
                    margin: 2.5vh 0;
                    transition: transform .7s, color .5s;

                    &:hover {
                        transform: translateX(10px);
                        
                        & > a {
                            color: $red_color;
                        }
                    }

                    & > a {
                        text-decoration: none;
                        color: #fff;
                        font-size: 21px;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
            }
        }

        &_cta {
            width: 125px;
            background-color: $red_color;
            height: fit-content;
            border-radius: 25px;
            font-size: 12px;
            padding: 5px;
            color: #fff;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: bold;
            transition: transform .8s;

            &:hover {
                transform: translateX(10px);
            }
        }

        &_menu {
            width: 35px;
            height: 35px;
            background-color: $red_color;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: none;
            padding: 5px;
            z-index: 99999;

            @media(min-width: 1280px){
                display: none;
            }

            &:focus {
                outline: none;
            }

            &_line {
                width: 100%;
                height: 3px;
                background-color: #fff;
                margin: 2.5px 0;
            }
        }
    }
}

.open {
    transform: translateY(0);
    position: fixed;
}

.no_bg {
    transition: all .6s;
    background-color: $blu_color;
    position: fixed;
    right: 20px;
}

.changeColor {
    transition: all .7s;
    background-color: $red_color;
}