.cont__services {
  width: 75%;
  display: flex;
  flex-direction: column;
  padding-inline-start: 0;
  align-items: center;
  list-style: none;
  margin: 5vh auto;
}
@media (min-width: 1280px) {
  .cont__services {
    width: 85%;
    flex-direction: row;
    justify-content: space-between;
    margin: 1vh auto;
  }
}
.cont__services > li {
  width: 100%;
  height: 40vh;
  clip-path: circle();
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  margin: 2.5vh 0;
}
@media (orientation: landscape) {
  .cont__services > li {
    height: 90vh;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .cont__services > li {
    height: 50vh;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .cont__services > li {
    height: 65vh;
  }
}
@media (min-width: 1280px) {
  .cont__services > li {
    width: 30%;
    height: 60vh;
    margin: 0 0;
  }
}
.cont__services > li > p {
  color: #fff;
  font-weight: bold;
  margin-top: 10px;
  width: fit-content;
}
@media (orientation: landscape) {
  .cont__services > li > p {
    width: 65%;
  }
}
.cont__services > li > a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 12px;
  transition: color 0.6s;
  background-color: #FF0000;
  transition: color 0.6s, background-color 0.7s;
  border-radius: 25px;
  padding: 5px;
  text-transform: uppercase;
  line-height: 15px;
  width: 65%;
  margin-top: -10px;
}
@media (orientation: landscape) {
  .cont__services > li > a {
    width: 40%;
    margin-top: unset;
  }
}
.cont__services > li > a:hover {
  background-color: #020E28;
  color: #FF0000;
}
.cont__services > li[id=home] {
  background-image: url("../img/foto/casa.png");
}
.cont__services > li[id=business] {
  background-image: url("../img/foto/business.png");
}
.cont__services > li[id=flat] {
  background-image: url("../img/foto/condomini.png");
}

